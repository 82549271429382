<template>
  <div class="legal">
    <heade></heade>
    <div
      class="cen_wrap"
      :style="{
        backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
      }"
    >
      <div class="cen_box nice-scroll">
        <div class="cen" v-html="articleh.content"></div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import $ from "jquery";
import { scroll } from "@utils/scroll";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import { getarticleh } from "@api/public";
export default {
  components: {
    heade,
    foot,
  },
  props: {},
  data() {
    return {
      articleh: {},
      queryWhere: {},
    };
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  mounted: function () {
    let that = this;
    getarticleh(that.queryWhere).then((res) => {
      that.$set(that, "articleh", res.data);
    });
    scroll($(".nice-scroll"));
  },
  created: function () {
    this.backgroud = this.$route.meta.background;
    document.title = this.$route.meta.title;
    this.queryWhere = this.$route.query;
  },
  methods: {
    go(src) {
      window.location.href = src;
    },
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>

<style>
.legal .cen_wrap {
  width: 100%;
  height: 1280px;
  background-size: cover;
  background-position: center 0;
}
.legal .cen_box {
  width: 1324px;
  height: 929px;
  position: relative;
  top: 188px;
  left: 285px;
  box-sizing: border-box;
  padding: 20px 100px;
}
.cen_box > .cen {
  width: 100%;
  height: 100%;
}
.cen_box > .cen::-webkit-scrollbar {
  width: 0;
}
.cen img {
  width: 800px;
}
.cen_box p {
  font-size: 16px;
  letter-spacing: 3px;
}
</style>